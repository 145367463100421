import { useEffect, useState } from "react";

export const useIsSmall = (threshold: number = 768) => {
  const [isSmall, setIsSmall] = useState(false);

  const handleResize = () => {
    if (typeof window !== "undefined") {
      setIsSmall(window.innerWidth < threshold);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isSmall;
};
