/**
 * @fileoverview UiShell component that provides the main layout structure for the application.
 * Handles responsive navigation, scroll-based animations, and layout management.
 */

import React, { useState, useEffect } from "react";
import {
  AppBar,
  Drawer,
  Toolbar,
  IconButton,
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { motion, useAnimation, AnimatePresence } from "framer-motion";
import { navItems } from "./UiShell.api";
import { useDispatch, useSelector } from "react-redux";
import {
  setScrollY,
  setScrollStarted,
} from "../../store/reducers/uishell.reducer";
import { RootState } from "../../store/store";

import { Footer } from "./Footer";
import { UiShellProps } from "./UiShell.types";
import { useScrollVisibility } from "./hooks/useScrollVisibility";
import { Logo } from "./components/Logo";
import { NavigationItems } from "./components/NavigationItems";
import { navigate } from "gatsby";

// Constants
const DRAWER_WIDTH = 240;
const SCROLL_THRESHOLD = 200;
const MOTION_INITIAL_Y = 0;
const MOTION_HIDDEN_Y = -100;

/**
 * UiShell Component
 *
 * @component
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Child components to render within the shell
 * @param {string} props.navItemColor - Color for navigation items
 */
export const UiShell: React.FC<UiShellProps> = ({ children, navItemColor }) => {
  // State and hooks
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const controls = useAnimation();
  const dispatch = useDispatch();
  const { scrollY: reduxScrollY } = useSelector(
    (state: RootState) => state.uishell
  );

  // Use our custom hook for scroll visibility
  const { isVisible, scrollPosition } = useScrollVisibility(isSmall);

  // Sync scroll position with Redux
  useEffect(() => {
    dispatch(setScrollY(scrollPosition));
    dispatch(setScrollStarted(scrollPosition > 0));
  }, [scrollPosition, dispatch]);

  // Handle scroll-based animations
  useEffect(() => {
    if (!isSmall) {
      controls.start({
        y: isVisible ? MOTION_INITIAL_Y : MOTION_HIDDEN_Y,
        transition: { duration: 0.3, ease: "easeInOut" },
      });
    }
  }, [isVisible, controls, isSmall]);

  // Event handlers
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  // Compute dynamic styles based on scroll position
  const appBarColor =
    scrollPosition > SCROLL_THRESHOLD
      ? theme.palette.mode === "dark"
        ? `rgba(0,0,0,0.4)`
        : `rgba(255,255,255,0.8)`
      : "transparent";
  const textColor =
    scrollPosition > SCROLL_THRESHOLD
      ? theme.palette.primary.main
      : navItemColor ?? "#fff";

  // Mobile drawer content
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box mb={2}>
        <Logo isSmall={true} />
      </Box>
      <Divider />
      <List>
        {navItems.map((item, idx) => (
          <ListItem key={idx} disablePadding>
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={() => navigate(item.url)}
              aria-label={`Navigate to ${item.name} page`}
            >
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      {/* Render AppBar with or without animation based on screen size */}
      <Box sx={{ width: "100%", overflow: "hidden" }}>
        <AnimatePresence>
          <motion.div
            animate={controls}
            initial={{ y: MOTION_INITIAL_Y }}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              zIndex: 1200,
              width: "100%",
            }}
          >
            <AppBar
              component="nav"
              position="fixed"
              elevation={0}
              sx={{
                background: isSmall ? "black" : appBarColor,
                transition: "background-color 0.3s ease-in-out",
                width: "100%",
                maxWidth: "100vw",
                overflow: "hidden",
                left: 0,
                right: 0,
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  overflow: "hidden",
                  px: { xs: 1, sm: 2 },
                }}
              >
                <Box
                  sx={{
                    maxWidth: isSmall ? "60%" : "auto",
                    overflow: "hidden",
                  }}
                >
                  <Logo
                    isSmall={isSmall}
                    useWhiteLogo={scrollPosition <= SCROLL_THRESHOLD}
                  />
                </Box>

                <Box
                  sx={{
                    maxWidth: isSmall ? "40%" : "auto",
                    overflow: "hidden",
                  }}
                >
                  {isSmall ? (
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      edge="end"
                      onClick={handleDrawerToggle}
                      sx={{
                        ml: 2,
                        position: "absolute",
                        right: theme.spacing(2),
                      }}
                    >
                      <MenuIcon color="primary" />
                    </IconButton>
                  ) : (
                    <Box sx={{ overflow: "hidden" }}>
                      <NavigationItems
                        navItems={navItems}
                        textColor={
                          isSmall ? theme.palette.primary.main : textColor
                        }
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </AppBar>
          </motion.div>
        </AnimatePresence>
      </Box>

      {/* Mobile Navigation Drawer */}
      <Box component="nav">
        <Drawer
          variant="temporary"
          anchor="right"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better mobile performance
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: DRAWER_WIDTH,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>

      {/* Main Content */}
      <Box component="main" sx={{ p: 0 }}>
        {children}
      </Box>

      {/* Footer */}
      <Footer />
    </>
  );
};
