import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  Alert,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useForm } from "react-hook-form";
import axios from "axios";
import { POST_ENGAGEMENT_CONSULTATION } from "../../constants/api";
import { LoadingButton } from "@mui/lab";

// Define the form data type
interface ConsultationFormInputs {
  firstName: string;
  lastName: string;
  email: string;
  company: string;
  phone?: string;
  role?: string;
  industry?: string;
  numberOfEmployees?: number;
  state?: string;
  country?: string;
  upgradeTiming?: string;
}

// Props for FormConsultation component
interface FormConsultationProps {
  onSuccess: () => void; // Function to call on successful submission
  requiredFields?: Array<keyof ConsultationFormInputs>;
}

// Modal component
export const FormConsultation: React.FC<FormConsultationProps> = ({
  onSuccess,
  requiredFields = [
    "firstName",
    "lastName",
    "email",
    "company",
    "role",
    "industry",
    "numberOfEmployees",
    "state",
    "country",
    "upgradeTiming",
  ],
}) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ConsultationFormInputs>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      company: "",
      phone: "",
    },
  });

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const onSubmit = async (data: ConsultationFormInputs) => {
    setLoading(true);
    setErrorMessage(""); // Reset error message
    setSuccessMessage(""); // Reset success message
    try {
      // Submit to POST_ENGAGEMENT_CONSULTATION using axios
      const response = await axios.post(POST_ENGAGEMENT_CONSULTATION, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      setSuccessMessage(
        response.data.message || "Form submitted successfully!"
      );
      onSuccess(); // Call the onSuccess function to close the modal
    } catch (error) {
      // Handle submission error
      console.error("Form submission error:", error);
      setErrorMessage("There was an error submitting the form.");
    } finally {
      setLoading(false);
    }
  };

  const renderField = (field: keyof ConsultationFormInputs) => {
    const fieldConfig: Record<
      keyof ConsultationFormInputs,
      {
        label: string;
        type: string;
        required: boolean;
      }
    > = {
      firstName: {
        label: "First Name",
        type: "text",
        required: requiredFields.includes("firstName"),
      },
      lastName: {
        label: "Last Name",
        type: "text",
        required: requiredFields.includes("lastName"),
      },
      email: {
        label: "Email",
        type: "email",
        required: requiredFields.includes("email"),
      },
      company: {
        label: "Company",
        type: "text",
        required: requiredFields.includes("company"),
      },
      phone: {
        label: "Phone (optional)",
        type: "tel",
        required: false,
      },
      role: {
        label: "Role",
        type: "text",
        required: requiredFields.includes("role"),
      },
      industry: {
        label: "Industry",
        type: "text",
        required: requiredFields.includes("industry"),
      },
      numberOfEmployees: {
        label: "Number of Employees",
        type: "number",
        required: requiredFields.includes("numberOfEmployees"),
      },
      state: {
        label: "State",
        type: "text",
        required: requiredFields.includes("state"),
      },
      country: {
        label: "Country",
        type: "text",
        required: requiredFields.includes("country"),
      },
      upgradeTiming: {
        label: "When are you looking to upgrade your info security?",
        type: "select",
        required: requiredFields.includes("upgradeTiming"),
      },
    };

    const config = fieldConfig[field];
    if (!config) return null;

    if (config.type === "select") {
      return (
        <TextField
          key={field}
          select
          label={config.label}
          {...register(field, {
            required: config.required ? `${config.label} is required` : false,
          })}
          error={!!errors[field]}
          helperText={errors[field]?.message}
          margin="normal"
          fullWidth
          size="small"
          aria-label={config.label}
          inputProps={{
            style: {
              color: theme.palette.grey[300],
            },
          }}
        >
          <MenuItem value="immediately">Immediately</MenuItem>
          <MenuItem value="3 months">In 3 months</MenuItem>
          <MenuItem value="6 months">In 6 months</MenuItem>
          <MenuItem value="more">6 months or more</MenuItem>
        </TextField>
      );
    }

    return (
      <TextField
        key={field}
        label={config.label}
        type={config.type}
        {...register(field, {
          required: config.required ? `${config.label} is required` : false,
        })}
        error={!!errors[field]}
        helperText={errors[field]?.message}
        margin="normal"
        fullWidth
        size="small"
        aria-label={config.label}
        inputProps={{
          style: {
            color: theme.palette.grey[300],
          },
        }}
      />
    );
  };

  return (
    <Box>
      {successMessage ? (
        <Alert severity="success" sx={{ mb: 2 }}>
          {successMessage}
        </Alert>
      ) : (
        <>
          <Typography variant="h6" component="h2" id="consultation-modal-title">
            We Look Forward to Meeting You
          </Typography>
          <form
            onSubmit={handleSubmit(onSubmit)}
            aria-label="Consultation request form"
            role="form"
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              {errorMessage && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {errorMessage}
                </Alert>
              )}
              {requiredFields.map((field) => renderField(field))}
              {renderField("phone")}
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                loading={loading}
                sx={{ mt: 2 }}
                aria-label="Submit consultation request"
              >
                Submit Consultation Request
              </LoadingButton>
            </Box>
          </form>
        </>
      )}
    </Box>
  );
};
