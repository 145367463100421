/**
 * @fileoverview Navigation items component that renders the main navigation buttons
 */

import React from "react";
import { Button, IconButton } from "@mui/material";
import { navigate } from "gatsby";
import LoginIcon from "@mui/icons-material/Login";
import { NavItem } from "../UiShell.types";
import { LOGIN } from "../../../constants/routes";

/**
 * Props for the NavigationItems component
 *
 * @interface NavigationItemsProps
 * @property {NavItem[]} navItems - Array of navigation items to display
 * @property {string} textColor - Color for the navigation text
 */
interface NavigationItemsProps {
  navItems: NavItem[];
  textColor: string;
}

/**
 * NavigationItems Component
 *
 * Renders the main navigation buttons and login icon.
 * Handles navigation to different routes and login functionality.
 *
 * @component
 * @param {NavigationItemsProps} props - Component props
 */
export const NavigationItems: React.FC<NavigationItemsProps> = ({
  navItems,
  textColor,
}) => {
  /**
   * Handles navigation to login page
   */
  const handleLogin = () => {
    navigate(LOGIN);
  };

  return (
    <>
      {/* Main Navigation Buttons */}
      {navItems.map((item, idx) => (
        <Button
          key={idx}
          sx={{ color: textColor }}
          onClick={() => navigate(item.url)}
          aria-label={`Navigate to ${item.name} page`}
        >
          {item.name}
        </Button>
      ))}
    </>
  );
};
