import React, { useState } from "react";
import {
  Grid,
  Container,
  Box,
  Button,
  TextField,
  useTheme,
  useMediaQuery,
  Snackbar,
  Alert,
  Typography,
} from "@mui/material";
import axios from "axios"; // Ensure axios is installed
import { useForm, SubmitHandler } from "react-hook-form";
import { POST_ENGAGEMENT_CONTACT } from "../../constants/api";
import { SegmentTitle } from "../../components/SegmentTitle";

interface FormContactFormInputs {
  name: string;
  email: string;
  company: string;
  role: string;
}

interface FormField {
  name: keyof FormContactFormInputs;
  label: string;
  type?: string;
  required?: boolean;
}

const formFields: FormField[] = [
  { name: "name", label: "Name", required: true },
  { name: "email", label: "Email", type: "email", required: true },
  { name: "company", label: "Company", required: true },
  { name: "role", label: "Role", required: true },
];

const StyledTextField: React.FC<{
  label: string;
  error?: boolean;
  helperText?: string;
  register: any;
  name: string;
  type?: string;
}> = ({ label, error, helperText, register, name, type }) => (
  <Box width="100%" mb={1}>
    <Typography color="white" variant="body2" sx={{ mb: 0.5 }}>
      {label}
    </Typography>
    <TextField
      {...register(name, { required: `${label} is required` })}
      error={error}
      helperText={helperText}
      margin="normal"
      fullWidth
      size="small"
      type={type}
      InputProps={{
        style: { backgroundColor: "white" },
      }}
      sx={{ mt: 0 }}
      aria-label={`Your ${label.toLowerCase()}`}
    />
  </Box>
);

const useSnackbar = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );

  const showSnackbar = (message: string, severity: "success" | "error") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return {
    snackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    showSnackbar,
    handleCloseSnackbar,
  };
};

export const FormContact: React.FC = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormContactFormInputs>();
  const {
    snackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    showSnackbar,
    handleCloseSnackbar,
  } = useSnackbar();

  const onSubmit: SubmitHandler<FormContactFormInputs> = async (data) => {
    try {
      await axios.post(POST_ENGAGEMENT_CONTACT, data);
      showSnackbar("Form submitted successfully!", "success");
      reset();
    } catch (error) {
      console.error("Submission error:", error);
      showSnackbar("Error submitting the form. Please try again.", "error");
    }
  };

  return (
    <Grid container spacing={isSmall ? 0 : 3}>
      <Grid item xs={12}>
        <Container maxWidth={isSmall ? "lg" : "sm"}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box textAlign="left">
                <SegmentTitle color="white" sx={{ fontSize: 34 }}>
                  Contact Us!
                </SegmentTitle>
              </Box>
            </Grid>
          </Grid>
          <form
            onSubmit={handleSubmit(onSubmit)}
            aria-label="Contact information form"
            role="form"
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              {formFields.map((field) => (
                <StyledTextField
                  key={field.name}
                  label={field.label}
                  error={!!errors[field.name]}
                  helperText={errors[field.name]?.message}
                  register={register}
                  name={field.name}
                  type={field.type}
                />
              ))}
            </Box>
            <Box display="flex" justifyContent={isSmall ? "center" : "right"}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                style={{ marginTop: "20px" }}
                aria-label="Submit contact information"
              >
                Submit Contact Information
              </Button>
            </Box>
          </form>
        </Container>
      </Grid>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
};
