import React, { useState, useEffect } from "react";
import { Box, Button, Typography, Snackbar, Alert } from "@mui/material";

const CONSENT_KEY = "user-consent";

export const ConsentBanner: React.FC = () => {
  // State to store if consent has been given, initially set to null to indicate loading
  const [consentGiven, setConsentGiven] = useState<boolean | null>(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Effect to initialize state with localStorage value once the component is mounted
  useEffect(() => {
    if (typeof window !== "undefined") {
      const consent = localStorage.getItem(CONSENT_KEY) === "true";
      setConsentGiven(consent);
    }
  }, []);

  // Effect to notify GTM if consent is given
  useEffect(() => {
    if (consentGiven && typeof window !== "undefined" && window.dataLayer) {
      window.dataLayer.push({
        event: "consent",
        status: "given",
      });
    }
  }, [consentGiven]);

  // Handle consent button click
  const handleConsent = () => {
    setConsentGiven(true);
    if (typeof window !== "undefined") {
      localStorage.setItem(CONSENT_KEY, "true");
    }
    setOpenSnackbar(true);
  };

  // Return null while the consent state is still being initialized
  if (consentGiven === null) {
    return null;
  }

  return (
    <>
      {!consentGiven && (
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            bgcolor: "background.paper",
            p: 2,
            boxShadow: 3,
            textAlign: "center",
            zIndex: 900,
          }}
          role="dialog"
          aria-labelledby="cookie-consent-title"
          aria-describedby="cookie-consent-description"
        >
          <Typography variant="body2" id="cookie-consent-description">
            We use cookies and other tracking technologies to improve your
            experience. By clicking "I Accept," you consent to the use of
            cookies.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConsent}
            sx={{ mt: 2 }}
            aria-label="Accept cookies and tracking"
          >
            I Accept
          </Button>
        </Box>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        aria-live="polite"
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          aria-label="Consent confirmation"
        >
          Consent given. Thank you!
        </Alert>
      </Snackbar>
    </>
  );
};
