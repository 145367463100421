import {
  HOME,
  ABOUT,
  DOCS,
  DOCS_GETTING_STARTED,
  DOCS_JAVASCRIPT,
  DOCS_PYTHON,
  ARC,
  FIELD_NOTES,
} from "../../constants/routes";
export function stringToColor(string: string): string {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

import { StringAvatarProps, NavItem } from "./UiShell.types";

export function stringAvatar(name: string): StringAvatarProps {
  const initials = name.split(" ");
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${initials[0][0]}${initials[1] ? initials[1][0] : ""}`,
  };
}

export const navItems: NavItem[] = [
  {
    name: "Home",
    url: HOME,
  },
  {
    name: "About",
    url: ABOUT,
  },
  {
    name: "Arc",
    url: ARC,
  },
  {
    name: "Field Notes",
    url: FIELD_NOTES,
  },
  // {
  //   name: "Documentation",
  //   url: DOCS_GETTING_STARTED,
  // },
];

export const docsNav: NavItem[] = [
  { name: "Getting Started", url: DOCS_GETTING_STARTED },
  { name: "JavaScript SDK", url: DOCS_JAVASCRIPT },
  { name: "Python SDK", url: DOCS_PYTHON },
];
