/**
 * @fileoverview Custom hook for managing scroll-based visibility animations
 */

import { useState, useRef, useEffect } from "react";
import { useScroll, useMotionValueEvent } from "framer-motion";

// Constants
const VISIBILITY_TIMEOUT = 500;
const SCROLL_THRESHOLD = 50; // Minimum scroll amount before hiding

/**
 * Hook to manage visibility state based on scroll position
 *
 * @param {boolean} isSmall - Whether the viewport is in mobile/small view
 * @returns {Object} Object containing visibility state and scroll position
 * @property {boolean} isVisible - Whether the element should be visible
 * @property {number} scrollPosition - Current scroll position
 */
export const useScrollVisibility = (isSmall: boolean) => {
  // State for visibility and scroll position
  const [isVisible, setIsVisible] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);
  const lastScrollY = useRef(0);

  // Framer Motion scroll hook
  const { scrollY } = useScroll();

  // Ref for debounce timer
  const scrollTimer = useRef<NodeJS.Timeout | null>(null);

  // Handle scroll events
  useMotionValueEvent(scrollY, "change", (latest) => {
    // Update scroll position
    setScrollPosition(latest);

    // Only handle visibility changes for non-mobile views
    if (!isSmall) {
      // Determine scroll direction
      const scrollingDown = latest > lastScrollY.current;
      lastScrollY.current = latest;

      // Only hide if scrolling down and past threshold
      if (scrollingDown && latest > SCROLL_THRESHOLD) {
        // Hide element immediately on scroll
        setIsVisible(false);

        // Clear existing timer if any
        if (scrollTimer.current) {
          clearTimeout(scrollTimer.current);
        }

        // Set timer to show element after scroll stops
        scrollTimer.current = setTimeout(() => {
          setIsVisible(true);
        }, VISIBILITY_TIMEOUT);
      } else if (!scrollingDown) {
        // Show immediately when scrolling up
        setIsVisible(true);

        // Clear existing timer if any
        if (scrollTimer.current) {
          clearTimeout(scrollTimer.current);
        }
      }
    }
  });

  // Clean up timer on unmount
  useEffect(() => {
    return () => {
      if (scrollTimer.current) {
        clearTimeout(scrollTimer.current);
      }
    };
  }, []);

  return { isVisible, scrollPosition };
};
