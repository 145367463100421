export const fontCdn: string =
  "https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&family=Roboto+Condensed:wght@300;400;500;700&display=swap";

const systemFonts =
  '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif';

export const headlineFont: string = `"Roboto Condensed", sans-serif`;
export const bodyFont: string = `"Roboto", sans-serif`;

export const fontFamilies: string = `${headlineFont},${bodyFont}`;
