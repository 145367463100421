import React from "react";
import { Box, Typography, TypographyProps } from "@mui/material";
import { useIsSmall } from "../../hooks/useIsSmall";

interface SegmentTitleProps extends Omit<TypographyProps, "children"> {
  children: React.ReactNode;
}

export const SegmentTitle: React.FC<SegmentTitleProps> = ({
  children,
  ...props
}) => {
  const isSmall = useIsSmall();
  return (
    <Box>
      <Typography
        variant="h2"
        {...props}
        textTransform={`uppercase`}
        fontWeight={600}
        fontSize={isSmall ? "2.25rem" : "3.5rem"}
      >
        {children}
      </Typography>
    </Box>
  );
};
