import React from "react";
import { Provider } from "react-redux";
import { CssBaseline, ThemeProvider as MuiThemeProvider } from "@mui/material";
import { AppHelmet } from "../AppHelmet";

import "../../fonts/fonts.css";

import { darkTheme } from "../../config/theme";
import { store } from "../../store/store";

interface AppProviderProps {
  children: React.ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  return (
    <Provider store={store}>
      <AppHelmet />
      <MuiThemeProvider theme={darkTheme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </Provider>
  );
};
