/**
 * @fileoverview Logo component that handles the application logo display and navigation
 */

import React from "react";
import { Box, ButtonBase } from "@mui/material";
import { navigate } from "gatsby";
import { HOME } from "../../../constants/routes";
import ImgLogo from "../../../images/logo.svg";
import ImgLogoWhite from "../../../images/logo-white.svg";

/**
 * Props for the Logo component
 *
 * @interface LogoProps
 * @property {boolean} isSmall - Whether the viewport is in mobile/small view
 * @property {boolean} [useWhiteLogo=false] - Whether to use the white version of the logo
 */
interface LogoProps {
  isSmall: boolean;
  useWhiteLogo?: boolean;
}

/**
 * Logo Component
 *
 * Renders the application logo and handles navigation to home page.
 * Supports both regular and white versions of the logo based on context.
 *
 * @component
 * @param {LogoProps} props - Component props
 */
export const Logo: React.FC<LogoProps> = ({
  isSmall,
  useWhiteLogo = false,
}) => {
  /**
   * Handles navigation to home page when logo is clicked
   */
  const handleClickHome = () => {
    navigate(HOME);
  };

  // Determine which logo variant to use
  const logoDef = useWhiteLogo && !isSmall ? ImgLogoWhite : ImgLogo;

  return (
    <ButtonBase onClick={handleClickHome} aria-label="Navigate to home page">
      <Box component="img" src={logoDef} width={`150px`} alt="Crittora logo" />
    </ButtonBase>
  );
};
