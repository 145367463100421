import React, { useState } from "react";
import { navigate } from "gatsby";
import {
  Container,
  Grid,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { FormContact } from "../../FormContact";
import { Social } from "../../Social";
import { FormConsultation } from "../../../components/FormConsultation";

import { content } from "./Footer.content";
import BgFooter from "../../../images/footer/bg-footer.webp";
import LogoTextWhite from "../../../images/logo-text-white.svg";

export const Footer: React.FC = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);

  const handleClickOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleClickNavigate = (url: string) => {
    navigate(url);
  };

  const handleAction = (action: string, url: string) => {
    if (action === "handleClickNavigate") {
      handleClickNavigate(url);
    } else if (action === "handleClickOpenModal") {
      handleClickOpenModal();
    }
  };

  return (
    <Box
      sx={{
        background: `url(${BgFooter})`,
        backgroundSize: "cover",
      }}
      p={isSmall ? 0 : 4}
    >
      <Container>
        <Grid container spacing={isSmall ? 0 : 3}>
          <Grid item xs={12} md={6}>
            {!isSmall && (
              <>
                <Grid container spacing={isSmall ? 2 : 3}>
                  {content.section.privacy.list.map((item, index) => (
                    <Grid item key={index}>
                      <Box mt={isSmall ? 4 : 0}>
                        <Button
                          onClick={() => handleAction(item.action, item.url)}
                          variant="text"
                          sx={{ color: `white`, fontSize: isSmall ? 14 : 18 }}
                          aria-label={`Footer navigation: ${item.title}`}
                        >
                          {item.title}
                        </Button>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </>
            )}

            <Grid container>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent={isSmall ? "center" : "flex-start"}
                >
                  <Box
                    my={isSmall ? 4 : 6}
                    component="img"
                    src={LogoTextWhite}
                    alt="Crittora - Return to homepage"
                    width={isSmall ? 150 : 350}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Social />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Box
                  mb={isSmall ? 4 : 0}
                  textAlign={isSmall ? "center" : "left"}
                >
                  <Typography color="white">{content.title}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormContact />
          </Grid>
          {isSmall && (
            <Box mt={4} width="100%">
              <Grid container justifyContent="space-between">
                {content.section.privacy.list.map((item, index) => (
                  <Grid item key={index}>
                    <Box mt={isSmall ? 4 : 0}>
                      <Button
                        onClick={() => handleAction(item.action, item.url)}
                        variant="text"
                        sx={{ color: `white`, fontSize: isSmall ? 14 : 18 }}
                        aria-label={`Footer navigation: ${item.title}`}
                      >
                        {item.title}
                      </Button>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </Grid>
      </Container>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth
        aria-labelledby="consultation-form-dialog-title"
      >
        <DialogTitle id="consultation-form-dialog-title">
          Consultation Form
        </DialogTitle>
        <DialogContent>
          <FormConsultation onSuccess={handleCloseModal} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};
