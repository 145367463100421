import * as React from "react";
import type { GatsbyBrowser } from "gatsby";
import { UiShell, UiShellDocs } from "./src/components/UiShell";
import wrapWithProvider from "./wrap-with-provider";
import { ConsentBanner } from "./src/components/ConsentBanner";

export const wrapRootElement = wrapWithProvider;

// Track font loading state
let fontLoadingPromise: Promise<void> | null = null;
let fontLoadingTimeout: NodeJS.Timeout | null = null;

// Cleanup function to prevent memory leaks
const cleanupFontLoading = () => {
  if (fontLoadingTimeout) {
    clearTimeout(fontLoadingTimeout);
    fontLoadingTimeout = null;
  }
  fontLoadingPromise = null;
};

// Check if fonts are already loaded
const checkFontsLoaded = async () => {
  if (!("fonts" in document)) return true;

  try {
    // Check both Roboto and Roboto Condensed with all weights
    const fontChecks = [
      "300 1em 'Roboto'",
      "400 1em 'Roboto'",
      "500 1em 'Roboto'",
      "700 1em 'Roboto'",
      "300 1em 'Roboto Condensed'",
      "400 1em 'Roboto Condensed'",
      "500 1em 'Roboto Condensed'",
      "700 1em 'Roboto Condensed'",
    ];

    // Check each font individually to handle partial loading
    const results = await Promise.all(
      fontChecks.map((font) => document.fonts.check(font))
    );

    // Return true only if all fonts are loaded
    return results.every((result) => result === true);
  } catch {
    return false;
  }
};

// Check if Google Fonts CDN is accessible
const checkGoogleFontsAccess = async () => {
  try {
    const response = await fetch(
      "https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap",
      {
        method: "HEAD",
        mode: "no-cors",
      }
    );
    return true;
  } catch {
    return false;
  }
};

// Check if local fonts.css is loaded
const checkLocalFontsLoaded = () => {
  const styleSheets = Array.from(document.styleSheets);
  return styleSheets.some(
    (sheet) =>
      sheet.href &&
      (sheet.href.includes("/fonts/fonts.css") ||
        sheet.href.includes("/assets/fonts/fonts.css"))
  );
};

// Handle font loading and style application
export const onInitialClientRender = async () => {
  // Cleanup any existing font loading
  cleanupFontLoading();

  // Add fonts-loading class initially
  document.documentElement.classList.add("fonts-loading");

  // Check if either Google Fonts or local fonts are loaded
  const googleFontsAccessible = await checkGoogleFontsAccess();
  const localFontsLoaded = checkLocalFontsLoaded();

  if (!googleFontsAccessible && !localFontsLoaded) {
    // If neither font source is available, show content immediately with system fonts
    requestAnimationFrame(() => {
      document.documentElement.classList.add("styles-loaded");
    });
    return;
  }

  // Wait a small amount of time to allow fonts to start loading
  await new Promise((resolve) => setTimeout(resolve, 100));

  // Check if fonts are already loaded
  const fontsLoaded = await checkFontsLoaded();

  if (fontsLoaded) {
    // If fonts are already loaded, show content immediately
    requestAnimationFrame(() => {
      document.documentElement.classList.remove("fonts-loading");
      document.documentElement.classList.add("styles-loaded");
    });
    return;
  }

  // Wait for fonts to load
  if ("fonts" in document) {
    try {
      // Load all fonts with a timeout
      fontLoadingPromise = Promise.all([
        // Load all Roboto weights
        document.fonts.load("300 1em 'Roboto'"),
        document.fonts.load("400 1em 'Roboto'"),
        document.fonts.load("500 1em 'Roboto'"),
        document.fonts.load("700 1em 'Roboto'"),
        // Load all Roboto Condensed weights
        document.fonts.load("300 1em 'Roboto Condensed'"),
        document.fonts.load("400 1em 'Roboto Condensed'"),
        document.fonts.load("500 1em 'Roboto Condensed'"),
        document.fonts.load("700 1em 'Roboto Condensed'"),
      ]);

      // Add a timeout to prevent infinite waiting
      fontLoadingTimeout = setTimeout(() => {
        cleanupFontLoading();
        throw new Error("Font loading timeout");
      }, 3000);

      await Promise.race([
        fontLoadingPromise,
        new Promise(
          (_, reject) =>
            fontLoadingTimeout &&
            setTimeout(() => reject(new Error("Font loading timeout")), 3000)
        ),
      ]);

      // Remove fonts-loading class
      document.documentElement.classList.remove("fonts-loading");
      // Show content once fonts are loaded
      requestAnimationFrame(() => {
        document.documentElement.classList.add("styles-loaded");
      });
    } catch {
      // If font loading fails or times out, still show content
      requestAnimationFrame(() => {
        document.documentElement.classList.add("styles-loaded");
      });
    } finally {
      cleanupFontLoading();
    }
  } else {
    // Fallback for browsers that don't support Font Loading API
    requestAnimationFrame(() => {
      document.documentElement.classList.add("styles-loaded");
    });
  }
};

// Cleanup on route change
export const onRouteUpdate = () => {
  cleanupFontLoading();
};

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props,
}) => {
  const { location } = props;

  if (location.pathname.startsWith("/docs")) {
    return (
      <UiShellDocs>
        {element}
        <ConsentBanner />
      </UiShellDocs>
    );
  }

  return (
    <UiShell>
      {element}
      <ConsentBanner />
    </UiShell>
  );
};
